import React, {useState, useEffect} from 'react';
import {Link} from 'gatsby';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {buildImageUrl, parseNameToUrl, toVND} from '~utils/helper';
import Plus from '~components/icons/plus';
import {generatePrice} from '~utils/com-helper';
import Favorite from '~components/icons/favorite';
import {post, remove} from '~utils/api';
import {membershipTitle} from '~constants/home';

import './styles.scss';

const ProductItem = ({item, isPromotion, isFirst, isLast, favoritePage, removeFavorite, updateCategoryState}) => {
  const [product, setProduct] = useState(null);

  useEffect(() => {
    setProduct(item);
  }, [item]);

  const handleAddCart = () => {
    console.log('Added cart');
  };

  const handleAddFavorite = async () => {
    if (favoritePage) {
      removeFavorite && removeFavorite(product.id);
      return;
    }

    product.isLike
      ? await remove('/v1/product/favorite-product', {id: product.id})
      : await post('/v1/product/favorite-product', {id: product.id});

    setProduct({...product, isLike: !product.isLike});
  };

  const linkToProductInfo = () => {
    if (updateCategoryState) updateCategoryState();
  };

  return (
    <div className='product-item' style={{marginLeft: isFirst ? '0' : '5px', marginRight: isLast ? '0' : '5px'}}>
      <div className={`favorite-area ${product?.isLike ? 'favorited' : ''}`} onClick={handleAddFavorite}>
        <div className='favorite-icon'>
          <Favorite fill='white' size={16} />
        </div>
      </div>
      <Link to={`/product/${parseNameToUrl(product?.name || '')}.prod.${product?.id || 0}`} onClick={linkToProductInfo}>
        <div className='product-image'>
          <LazyLoadImage
            key={item.id}
            src={buildImageUrl(item.primaryImageUrl, '208x308')}
            alt={item.name}
            title={item.name}
            width={208}
            height={308}
          />
          <div hidden={!item.isComing} className='coming-soon'>
            <div className='label'>Hàng sắp về</div>
          </div>
          <div hidden={!item.isHot} className='hot-product'>
            <div className='label'>HOT</div>
          </div>
        </div>
        <div className='item-content'>
          <div className='item-title' title={item.name}>
            {item.name}
          </div>
          {isPromotion && (
            <div className='promotion'>
              <span className='promotion-icon'>{item.promotion || 20}% Off</span>
              <span className='total-sale'>Đã bán: {item.totalSale || 0}</span>
            </div>
          )}
          <div className='item-price'>Giá bán: {generatePrice(item.originalPrice, item.salePrice)}</div>
          <div className='item-price item-rental'>
            Giá thuê: {generatePrice(item.originalRentalPrice, item.rentalPrice)}
          </div>
          {item.membershipRentalPrice ? (
            <div className='item-price item-rental'>Giá membership: {toVND(item.membershipRentalPrice)}</div>
          ) : (
            <div className='item-price membership'>{membershipTitle}</div>
          )}
        </div>
      </Link>
      <div className='add-cart' onClick={handleAddCart}>
        <div className='icon'>
          <div className='p-icon'>
            <Plus size='16' fill='#ffffff' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
