import React from 'react';

const Calendar = ({fill = '#828282', size = '24'}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    version='1.1'
    viewBox='0 0 42 48'
    width={size}
    height={size}
    fill={fill}
  >
    <path d='M39.274 4.5323V45.625H2.35688V4.5323H0V48H41.6279V4.5323H39.274ZM7.21855 0H4.85872V8.56002H7.21855V0ZM13.1318 0H10.772V8.56002H13.1318V0ZM19.0417 0H16.6852V8.56002H19.0417V0ZM24.952 0H22.5921V8.56002H24.952V0ZM30.8652 0H28.5083V8.56002H30.8652V0ZM36.7781 0H34.4182V8.56002H36.7781V0ZM14.8782 22.1661V38.0897H17.2318V16.4794L10.4817 23.2295L12.1466 24.8974L14.8782 22.1661ZM29.4872 38.0775V16.4676L22.7371 23.2177L24.4079 24.8856L27.1274 22.1661V38.0897L29.4872 38.0775Z' />
    <rect x='10' y='16' width='20' height='24' fill='#ffffff' />
    <path d='M20.8945 26.0273C21.5326 27.1758 22.0885 27.9961 22.5625 28.4883C23.3281 29.2357 24.2305 29.6094 25.2695 29.6094C26.5456 29.6094 27.5299 29.1536 28.2227 28.2422C28.7695 27.5312 29.043 26.7474 29.043 25.8906C29.043 24.8151 28.7057 23.931 28.0312 23.2383C27.3568 22.5456 26.4453 22.1992 25.2969 22.1992C24.0755 22.1992 23.1003 22.582 22.3711 23.3477C21.8789 23.8763 21.3867 24.7695 20.8945 26.0273ZM12.9648 26.1641C12.9648 28.0417 13.849 28.9896 15.6172 29.0078C17.0208 29.0078 18.1419 28.0781 18.9805 26.2188C18.1784 24.1771 17.0938 23.1654 15.7266 23.1836C13.8854 23.1654 12.9648 24.1589 12.9648 26.1641ZM30.793 26.0547C30.793 27.6042 30.3464 28.9167 29.4531 29.9922C28.4505 31.1953 27.0833 31.7969 25.3516 31.7969C24.2943 31.7969 23.2826 31.487 22.3164 30.8672C21.2773 30.1562 20.4753 29.181 19.9102 27.9414C19.2539 28.9622 18.6797 29.6823 18.1875 30.1016C17.4948 30.6484 16.6745 30.9128 15.7266 30.8945C14.2682 30.8945 13.1562 30.3841 12.3906 29.3633C11.7344 28.4883 11.4062 27.3581 11.4062 25.9727C11.4062 24.7331 11.7617 23.6576 12.4727 22.7461C13.2565 21.7617 14.2773 21.2695 15.5352 21.2695C17.3398 21.2695 18.7799 22.2812 19.8555 24.3047C21.0404 21.4792 22.8542 20.0664 25.2969 20.0664C26.8646 20.0664 28.1315 20.5404 29.0977 21.4883C30.2279 22.5638 30.793 24.0859 30.793 26.0547Z' />
  </svg>
);

export default Calendar;
