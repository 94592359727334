import React from 'react';

import Wardrobe from '~components/icons/wardrobe';
import Endow from '~components/icons/endow';
import TrueStream from '~components/icons/true-stream';
import Calendar from '~components/icons/calendar';

export const utilities = [
  {
    key: 'wardrobe',
    title: 'Tủ Đồ Rentzy',
    content: '+5000 Sản phẩm cao cấp đa dạng phong cách',
    icon: <Wardrobe size='54' />,
  },
  {
    key: 'endow',
    title: 'Ưu Đãi "Khủng"',
    content: 'giảm 50% toàn bộ giá thuê sản phẩm',
    icon: <Endow size='54' />,
  },
  {
    key: 'true-stream',
    title: 'TrueStream',
    content: 'Miễn phí giặt hấp bằng công nghệ TrueSteam hiện đại',
    icon: <TrueStream size='54' />,
  },
  {
    key: 'calendar',
    title: 'Lịch Thuê Tùy Thích',
    content: 'thoả thích thuê ngay hoặc theo lịch tuỳ chọn',
    icon: <Calendar size='54' />,
  },
];

export const membershipTitle = 'Giảm tới 50% với gói thành viên';

export default {
  utilities,
};
