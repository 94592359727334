import React from 'react';

const TrueStream = ({fill = '#828282', size = '24'}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    version='1.1'
    viewBox='0 0 40 54'
    width={size}
    height={size}
    fill={fill}
  >
    <path d='M0 0V54H39.0685V0H0ZM2.36871 2.37168H36.7097V13.1341H2.36871V2.37168ZM2.36871 51.6283V15.5058H36.7097V51.6283H2.36871ZM19.5327 20.3696C16.8195 20.3696 14.1672 21.1741 11.9113 22.6814C9.6553 24.1888 7.89687 26.3317 6.85857 28.8384C5.82027 31.345 5.54859 34.1031 6.07792 36.7642C6.60724 39.4252 7.91394 41.8696 9.83248 43.7881C11.751 45.7066 14.1953 47.0131 16.8564 47.5424C19.5175 48.0718 22.2757 47.8001 24.7824 46.7618C27.2891 45.7235 29.4315 43.9655 30.9389 41.7095C32.4463 39.4535 33.2508 36.8011 33.2508 34.0879C33.2512 32.2863 32.8968 30.5021 32.2075 28.8376C31.5183 27.173 30.5078 25.6605 29.2338 24.3865C27.9599 23.1126 26.4476 22.1025 24.783 21.4132C23.1184 20.724 21.3343 20.3691 19.5327 20.3696ZM19.5327 45.4342C17.2866 45.4342 15.091 44.7682 13.2234 43.5203C11.3559 42.2725 9.90045 40.4992 9.04093 38.4241C8.18141 36.349 7.95656 34.0656 8.39474 31.8628C8.83292 29.6599 9.91452 27.6362 11.5027 26.048C13.0909 24.4599 15.1144 23.3782 17.3172 22.9401C19.5201 22.5019 21.8035 22.7269 23.8786 23.5865C25.9536 24.446 27.7272 25.9015 28.975 27.769C30.2228 29.6365 30.8888 31.832 30.8888 34.078C30.8888 37.0898 29.6923 39.9787 27.5626 42.1083C25.4329 44.238 22.5445 45.4342 19.5327 45.4342ZM5.32122 8.97459H17.3263V6.60291H5.32122V8.97459ZM30.2108 8.97459H33.7441V6.60291H30.2205L30.2108 8.97459Z' />
  </svg>
);

export default TrueStream;
