import React from 'react';

const Wardrobe = ({fill = '#828282', size = '24'}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    version='1.1'
    viewBox='0 0 54 40'
    width={size}
    height={size}
    fill={fill}
  >
    <path d='M3.44671 38.9358C2.53353 38.9353 1.65796 38.5728 1.0121 37.9272C0.691501 37.6073 0.437205 37.2271 0.263543 36.8088C0.0898798 36.3905 0.000261143 35.942 0 35.4891V32.669C0.000459445 32.0341 0.176211 31.4117 0.50796 30.8704C0.839709 30.3291 1.31449 29.8899 1.87999 29.6013L20.1543 20.2262L21.1821 22.2347L2.90777 31.6098C2.71246 31.7087 2.54891 31.8606 2.43538 32.0478C2.32185 32.235 2.263 32.45 2.26555 32.669V35.4891C2.26346 35.6457 2.29354 35.801 2.35391 35.9455C2.41427 36.0899 2.50344 36.2206 2.6163 36.3291C2.7246 36.4412 2.85491 36.5298 2.9988 36.5896C3.14269 36.6494 3.29701 36.6791 3.45283 36.6768H50.5642C50.7213 36.6773 50.8769 36.6462 51.0217 36.5854C51.1665 36.5246 51.2977 36.4351 51.4073 36.3226C51.6239 36.1027 51.7464 35.8073 51.7488 35.4987V35.4673L51.6421 32.6254C51.6361 32.4172 51.5751 32.2142 51.465 32.0375C51.3549 31.8607 51.1998 31.7165 51.0156 31.6194L29.7455 19.7592L29.7738 14.5109L30.5541 14.2634C31.7932 13.8635 32.8732 13.0803 33.639 12.0273C34.4048 10.9744 34.8168 9.70553 34.8155 8.40353C34.8147 6.77299 34.167 5.20967 33.0144 4.05642C31.8617 2.90316 30.2982 2.25459 28.6676 2.25293C27.0371 2.25459 25.4739 2.90316 24.3213 4.05642C23.1686 5.20967 22.5209 6.77299 22.5201 8.40353H20.2637C20.2637 6.17468 21.1494 4.03742 22.7255 2.46139C24.3015 0.885354 26.4388 5.84904e-07 28.6676 5.84904e-07C29.7714 -0.000411178 30.8647 0.216587 31.8844 0.638776C32.9042 1.06097 33.8308 1.67979 34.6113 2.46024C35.3918 3.2407 36.0106 4.16729 36.4328 5.18709C36.8549 6.20688 37.0719 7.2998 37.0715 8.40353C37.0732 10.041 36.5963 11.6434 35.6995 13.0134C34.8027 14.3835 33.5251 15.4616 32.0237 16.1151V18.44L52.131 29.6453C52.6517 29.9345 53.0882 30.3545 53.397 30.8639C53.7059 31.3733 53.8764 31.9545 53.892 32.55L53.9987 35.4015C54.0004 35.4339 54.0004 35.4663 53.9987 35.4987C53.9975 36.3924 53.6491 37.2505 53.0271 37.8924C52.7065 38.222 52.3231 38.4843 51.8995 38.6635C51.476 38.8427 51.021 38.9352 50.5612 38.9358H3.44977H3.44671Z' />
  </svg>
);

export default Wardrobe;
